<template>
	<div></div>
</template>

<script>
import {
	deleteSnsLoginCookies,
	getInviteCodeCookie,
	getOAuthResultCookie,
	removeInviteCodeCookie,
} from '@/utils/cookies';
import ConcurrentAccess from '@/components/login/ConcurrentAccessModal.vue';
import { CONCURRENT_ACCESS_MODAL_STYLE } from '@/utils/constants';
import { mixinLinkAccount } from '@/components/login/mixinsLinkAccount';

export default {
	data() {
		return {
			tokenPrefix: 'Bearer ',
		};
	},
	mixins: [mixinLinkAccount],
	created() {
		const { accessToken, isLinkedAccount, isMainSnsId, concurrentAccess, ...signupData } = getOAuthResultCookie();

		deleteSnsLoginCookies();

		if (accessToken && isLinkedAccount && isMainSnsId) {
			//TODO: 만약 연동은 되어있지만, sns id, 즉 1.같은 사용자 2.같은 소셜 업체 3. 다른 소셜 이메일(snsID) 접속인 경우는 어떻게 처리할까?
			const token = this.tokenPrefix + accessToken;
			sessionStorage.setItem('authToken', token);
			this.loginConcurrentAccess(concurrentAccess, token);

			const inviteCodeCookie = getInviteCodeCookie();
			if (inviteCodeCookie) {
				this.$store.commit('SET_INVITE_CODE', inviteCodeCookie);
				removeInviteCodeCookie();
			}

			this.linkService().finally(() => this.$router.push('/home'));
			return;
		}
		// TODO: 회원가입이 되지 않은 유저 혹은 연동되지 않은 유저
		this.$router.replace({
			name: 'Login',
			params: { isLinkedAccount, isMainSnsId, signupData },
		});
	},
	methods: {
		loginConcurrentAccess(concurrentAccess, token) {
			if (concurrentAccess == 'true') {
				this.$modal.show(
					ConcurrentAccess,
					{ loginId: this.email, token: token },
					Object.assign({}, CONCURRENT_ACCESS_MODAL_STYLE, { name: ConcurrentAccess.name, clickToClose: false }),
				);
			}
		},
	},
};
</script>

<style></style>
